.notOpend{
    opacity: 0;
    max-height: 0;
    overflow-y: hidden;
    transition: all 1s ease;
   
}
.opened{
    opacity: 1;
    max-height: 80px;
    transition: all 1s ease;
    span{
        &:hover{
            background-color: #077bc4;
            color: white;
            transition: 0.8s ease-in;
        }
    }
}
.notRotate{
    transform: rotate(0deg);
    transition: all 0.4s ease;
}
.rotate{
    transform: rotate(180deg);
    transition: all 0.4s ease;
}
.notOpenForm{
    opacity: 0;
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.8s ease;
}
.openForm{
    opacity: 1;
    max-height: 350px;
    transition: all 1s ease;
}


/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }  