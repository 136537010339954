.addCart{
    // margin-top: 24px;
    &:hover{
        background-color: royalblue;
        transition: all 0.4s ease-out;
    }
}
.buy{
    // background-color: orangered;
    &:hover{
        background-color: orange;
        transition: all 0.4s ease-in;
    }
}
.images{
    // width: 100%;
    // height: 110px;
    // overflow-x: scroll;
    gap: 5px;
    display: flex;
    align-items: center;
    .img1{
        width: 55px;
        height: 55px;
        object-fit: cover;
        cursor: pointer;
        border: 1px solid rgba(0,0,0,0.2);
    }
 
}

.scroll-container {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch; /* for smooth scrolling on touch devices */
}