@import "../../../css-config/mixins.scss";

.catProdSection{
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 5px 5px 5px 5px;
    padding: 5px;
    margin-bottom: 5px;
    .catProdContainer{
        background-color: black;
        overflow: hidden;
        div{
            h1{
                color: white;
                padding: 10px 0 3px 10px; 
                font-size: 14px;
                position: relative;
                &::after{
                 content: "";
                 position: absolute;
                 bottom: -3px;
                 left: 8px;
                 width: 15%;
                 height: 3px;
                 background-color: #077bc4;
               }
            }
        }
        .catsubCategories{
            display: flex;
            align-items: center;
            gap: 10px;
            text-transform: capitalize;
            color: white;
            font-size: 13px;
            font-weight: 500;
            margin-top: 0;
            margin-left: 10px;
            margin-bottom: 10px;
            border-bottom: 3px solid rgb(255, 255, 255,0.9);
            span{
                cursor: pointer;
               
            }
        }
        .catSecProductCard{
            margin: 0 2px 2px 5px;
        }
    }
    @include sm{
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 5px 5px 5px 5px;
        padding: 5px;
        margin-bottom: 10px;
        .catProdContainer{
            background-color: black;
            overflow: hidden;
            div{
                h1{
                    color: white;
                    padding: 5px 0 0 10px; 
                    font-size: 16px;
                    margin-bottom: 0;
                    position: relative;
                    &::after{
                     content: "";
                     position: absolute;
                     bottom: -3px;
                     left: 10px;
                     width: 15%;
                     height: 3px;
                     background-color: #077bc4;
                   }
                }
                .catsubCategories{
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    text-transform: capitalize;
                    color: white;
                    font-size: 13px;
                    font-weight: 400;
                    margin-top: 5px;
                    margin-left: 15px;
                    margin-bottom: 5px;
                    border-bottom: 0;
                    span{
                        cursor: pointer;
                      
                    }
                }
            }
            .catSecProductCard{
                margin: 0 11px 10px 11px;
            }
        }
    }
    @include md{
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 5px 5px 5px 5px;
        padding: 15px;
        margin-bottom: 30px;
        .catProdContainer{
            background-color: black;
            overflow: hidden;
            div{
                h1{
                    color: white;
                    padding: 20px 0 0 20px; 
                    font-size: 16px;
                    margin-bottom: 25px;
                    position: relative;
                    &::after{
                     content: "";
                     position: absolute;
                     bottom: -3px;
                     left: 25px;
                     width: 15%;
                     height: 3px;
                     background-color: #077bc4;
                   }
                }
                .catsubCategories{
                    display: flex;
                    align-items: start;
                    gap: 25px;
                    text-transform: capitalize;
                    color: white;
                    font-size: 13px;
                    font-weight: 400;
                    margin-top: 20px;
                    margin-left: 0;
                    margin-bottom: 20px;
                    border-bottom: 0;
                    span{
                        cursor: pointer;
                  
                    }
                }
            }
            .catSecProductCard{
                margin: 0 23px 25px 23px;
            }
        }
    }
    @include lg{
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 5px 5px 5px 5px;
        padding: 10px;
        margin-bottom: 50px;
        .catProdContainer{
            background-color: black;
            overflow: hidden;
            div{
                h1{
                    color: white;
                    padding: 20px 0 0 25px; 
                    font-size: 16px;
                    margin-bottom: 25px;
                    position: relative;
                    &::after{
                     content: "";
                     position: absolute;
                     bottom: -3px;
                     left: 25px;
                     width: 15%;
                     height: 3px;
                     background-color: #077bc4;
                   }
                }
                .catsubCategories{
                    display: flex;
                    align-items: start;
                    gap: 25px;
                    text-transform: capitalize;
                    color: white;
                    font-size: 13px;
                    font-weight: 400;
                    margin-top: 20px;
                    margin-left: 0;
                    margin-bottom: 20px;
                    border-bottom: 0;
                    span{
                        cursor: pointer;
                        
                    }
                }
            }
            .catSecProductCard{
                margin: 0 23px 15px 23px;
            }
        }
    }
    @include xl{
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 5px 5px 5px 5px;
        padding: 15px;
        margin-bottom: 50px;
        .catProdContainer{
            background-color: black;
            overflow: hidden;
            div{
                h1{
                    color: white;
                    padding: 20px 0 0 25px; 
                    font-size: 16px;
                    margin-bottom: 25px;
                    position: relative;
                    &::after{
                     content: "";
                     position: absolute;
                     bottom: -3px;
                     left: 25px;
                     width: 15%;
                     height: 3px;
                     background-color: #077bc4;
                   }
                }
                .catsubCategories{
                    display: flex;
                    align-items: start;
                    gap: 25px;
                    text-transform: capitalize;
                    color: white;
                    font-size: 13px;
                    font-weight: 500;
                    margin-top: 20px;
                    margin-left: 0;
                    margin-bottom: 20px;
                    border-bottom: 0;
                    span{
                        cursor: pointer;
                        
                    }
                }
            }
            .catSecProductCard{
                margin: 0 20px 15px 20px;
            }
        }
    }
    @include xxl{
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 5px 5px 5px 5px;
        padding: 15px;
        margin-bottom: 50px;
        .catProdContainer{
            background-color: black;
            overflow: hidden;
            div{
                h1{
                    color: white;
                    padding: 20px 0 0 25px; 
                    font-size: 16px;
                    margin-bottom: 25px;
                    position: relative;
                    &::after{
                     content: "";
                     position: absolute;
                     bottom: -3px;
                     left: 25px;
                     width: 25%;
                     height: 3px;
                     background-color: #077bc4;
                   }
                }
                .catsubCategories{
                    display: flex;
                    align-items: start;
                    gap: 25px;
                    text-transform: capitalize;
                    color: white;
                    font-size: 13px;
                    font-weight: 500;
                    margin-top: 20px;
                    margin-left: 0;
                    margin-bottom: 20px;
                    border-bottom: 0;
                    span{
                        cursor: pointer;
                        &:hover{
                            color: #077bc4;
                        }
                    }
                }
            }
         
            .catSecProductCard{
                margin: 0 25px 20px 25px;
            }
        }
    }
}