@import "../../css-config/mixins.scss";
.carousle{
    display: flex;
    flex:end;
    height: 130px;
    width: 96%;
    margin-top: 80px;
    @include sm{
        display: flex;
        flex:end;
        height: 200px;
        width: 95%;
        margin-top: 80px;
    }
    @include md{
        display: flex;
        // justify-content: center;
        flex: end !important;
        height: 320px;
        width: 96%;
        margin-top: 80px;
    }
    @include lg{
        margin-top: 90px;
        height: 350px;
        width: 98%;
    }
    @include xl{
        margin-top: 0;
        height: 280px;
        padding:20px 0;
        width: 54%;
        // max-width: 636px;
    }
    @include xxl{
        margin-top: 0;
        padding:20px 0;
        height: 280px;
        width: 53%;
        // max-width: 100%;
    }
    .carousle_wrapper{
        position: relative;
        width: 100%;
        height: 100%;
        .carousle_card{
            display: flex;
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 2px 0 2px 0;
            -webkit-box-shadow: 0px 0px 11px 3px rgba(0,0,0,0.10);
            box-shadow: 0px 0px 11px 3px rgba(0,0,0,0.10) ;
            opacity: 0;
            pointer-events: none;
            transform: scale(.8);
            transition: 0.3s ease-in;
            img{
                width: 100%;
                object-fit: cover;
            }
        }
        .carousle_card-active{
            opacity: 1;
            transform: scale(1);
            pointer-events: visible;    
        }
        .arrow_left{
            position: absolute;
            font-size: 15px;
            top: 50%;
            transform: translate(0,-50%);
            background-color: gainsboro;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            cursor: pointer;
            left: 30px;
            @include md{
                position: absolute;
                font-size: 20px;
                top: 50%;
                transform: translate(0,-50%);
                background-color: gainsboro;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                cursor: pointer;
                left: 30px;
            }
            @include xl{
                position: absolute;
                font-size: 15px;
                top: 50%;
                transform: translate(0,-50%);
                background-color: gainsboro;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 22px;
                height: 22px;
                border-radius: 50%;
                cursor: pointer;
                left: 30px;
            }
        }
        .arrow_right{
            position: absolute;
            font-size: 15px;
            top: 50%;
            transform: translate(0,-50%);
            background-color: gainsboro;
            display: flex;
            justify-content: center;
            align-items: center; 
            width: 20px;
            height: 20px;
            border-radius: 50%;
            cursor: pointer;
            right: 30px;
            @include md{
                position: absolute;
                font-size: 20px;
                top: 50%;
                transform: translate(0,-50%);
                background-color: gainsboro;
                display: flex;
                justify-content: center;
                align-items: center; 
                width: 30px;
                height: 30px;
                border-radius: 50%;
                cursor: pointer;
                right: 30px;
            }
            @include xl{
                position: absolute;
                font-size: 15px;
                top: 50%;
                transform: translate(0,-50%);
                background-color: gainsboro;
                display: flex;
                justify-content: center;
                align-items: center; 
                width: 22px;
                height: 22px;
                border-radius: 50%;
                cursor: pointer;
                right: 30px;
            }
        }
        .pagination{
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translate(-50%,0);
            .pagination_dot{
                height: 7px;
                width: 7px;
                background-color: #f5f5f5;
                border-radius: 50%;
                display: inline-block;
                margin-left: 5px;
                cursor: pointer;
                @include md{
                    height: 10px;
                    width: 10px;
                    background-color: #f5f5f5;
                    border-radius: 50%;
                    display: inline-block;
                    margin-left: 10px;
                    cursor: pointer;
                }
                @include xl{
                    height: 8px;
                    width: 8px;
                    background-color: #f5f5f5;
                    border-radius: 50%;
                    display: inline-block;
                    margin-left: 6px;
                    cursor: pointer;
                }
                &:hover{
                    transform: scale(1.2);
                }
            }
            .pagination_dot-active{
                background-color: steelblue;
                transform: scale(.8);
            }
        }
    }
}