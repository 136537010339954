#imageZoom {
    position: relative;
    overflow: hidden; /* Ensures the zoom effect is clipped to the container */
  }
  
#imageZoom::after {
    display: var(--display);
    content: '';
    width: 100%;
    height: 100%;
    background-color: black;
    background-image: var(--url);
    background-size: 200%; /* Set initial zoom level */
    background-position: var(--zoom-x) var(--zoom-y);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; /* Ensures it appears above the image */
    transition: background-size 0.3s ease, background-position 0.3s ease;
  }
  


// #imageZoom {
//     cursor: zoom-in;
//     overflow: hidden;
//     border: 2px solid #ddd; /* Optional: Add a border */
//     border-radius: 10px;    /* Optional: Add rounded corners */
//   }
  
  