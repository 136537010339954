.dropDownhide{
    z-index: 0;
    opacity: 0;
    // transform: translateY(-30px);
    transform: scale(0);
}
.dropDownshow{
    z-index: 8;
    // transform: translateY(-30px);
    opacity: 1;
    transform: scale(0);
    animation: catSlide 0.8s ease forwards;
}

@keyframes catSlide{
    // 0%{transform: translateY(-30px);}
    // 100%{transform: translateY(0);}
    0%{transform: scale(0);}
    100%{transform: scale(1);}
}
.headerAnimation{
    transform: translateY(-100%);
    animation: slideHeader 0.6s ease forwards;
}
@keyframes slideHeader {
    0%{transform: translateY(-100%);}
    100%{transform: translateY(0%);}
}
.openSideBar{
    transform: translateX(-100%);
    animation: slideMobileSideBar 0.6s ease forwards;
}
@keyframes slideMobileSideBar {
    0%{transform: translateX(-100%);}
    100%{transform: translateX(0%);}
}