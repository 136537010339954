.catData{
    border-top: 1px solid rgba(0,0,0,0.1);
    cursor: pointer;
    &:nth-child(1){
        border-top: nones;
    }
    .arrow{
        color: rgba(0,0,0,0.5);
    }
}

.subCategory{
    width: 13rem;
    position: absolute;
    background-color: #eaeaea;
    right: -208px;
    top: 0;
    z-index: 999;
    transform: translateX(-0.7rem);
    animation: slideSubCat 0.3s ease forwards;
    .subCategoryItems{
        align-items: center;
        width: 100%;
        div{
            // display: flex;
            // align-items: center;
            .subCategory-item{
                // padding: .8rem;
                // font-size: 14px;
                // color: gray;
                // text-align: start;
                &:hover{
                    color: #007bc4;
                }
            }
            svg{
                font-size: 14px;
                margin-left: 10px;
            }
        }
        
       
       
    }
   
    
}

@keyframes slideSubCat {
    0%{transform: translateX(-0.7rem);}
    100%{transform: translateX(0);}
}