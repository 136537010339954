.cartPopup{
    transform: translateX(100%);
    animation: slideCartPopup 0.8s ease forwards;
}
@keyframes slideCartPopup {
    0%{transform: translateX(100%);}
    100%{transform: translateX(0%);}
}

.empty-cart{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 100px;
    svg{
        font-size: 100px;
        opacity: 0.1;
    }
    .return-cta{
        outline: 0;
        border: 0;
        height: 40px;
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 13px;
        color: white;
        background: #31bd03;
        border-bottom: 3px solid #31bd03 ;
        text-transform: uppercase;
    }
  }

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }  