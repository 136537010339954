@import "../../css-config/mixins.scss";
.card-container2{
    position: relative;
    .btn2{
        position: absolute;
        bottom: 10px;
       
        color: black;
       
        left: 50%;
        transform: translateX(-50%);
        overflow: hidden;
        width: 100%;
       @include sm{
        position: absolute;
        bottom: 10px;
       
        color: black;
       
        left: 50%;
        transform: translateX(-50%);
        overflow: hidden;
        width: 100%;
       }
       @include md{
        position: absolute;
        bottom: 10px;
       
        color: black;
       
        left: 50%;
        transform: translateX(-50%);
        overflow: hidden;
        width: 100%;
       }
       @include xxl{
        position: absolute;
        bottom: 10px;
       
        color: black;
       
        left: 50%;
        transform: translateX(-50%);
        overflow: hidden;
        width: 70%;
        margin: 0 auto;
        
       }
       .add-to-cart2{
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 400;
        margin: 0 auto;
        text-align: center;
        @include sm{
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 400;
            margin: 0 auto;
            text-align: center;
        }
        @include md{
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 400;
            margin: 0 auto;
            text-align: center;
        }
        @include lg{
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 400;
            margin: 0 auto;
            text-align: center;
        }
        @include xl{
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 400;
            margin: 0 auto;
            text-align: center;
        }
        @include xxl{
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 400;
            margin: 0 auto;
            text-align: center;
            padding: 3px 0;
            &:hover{
                border: 1px solid rgb(0, 0, 0,0.1);
                background-color: rgb(0, 123, 196,0.7);
                color: #fff;
                border-radius: 3px;
                transition: 1s ease-out;
               }
        }
       }


    }
    .imgContainer2{
        position: relative;
        overflow: hidden;
        @include sm{
            .quickView2{
                display: none;
            }
        }
        @include xxl{
            .quickView2{
                display: none;
            }
            &:hover{
                .img{
                    transform: scale(1.1);
                    transition: 1.5s ease-in;
                }
                .quickView2{
                    display: block;
                    position: absolute;
                    background-color: rgb(0, 0, 0,0.6);
                    color: white;
                    top: 15px;
                    right: 18px;
                    padding: 4px;
                    border: 1px solid rgba(0,0,0,0.2);
                    border-radius: 10px;
                    transform: translateX(100%);
                    animation: slide 0.5s ease forwards;
                }
            }
        }
    }
}
@keyframes slide {
    0%{transform: translateX(100%);}
    100%{transform: translateX(0);}
}