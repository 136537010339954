@import "../../css-config/mixins.scss";
.card-container4{
    position: relative;
    .btn4{
       position: absolute;
       bottom: 10px;
     
       color: black;
    
       left: 50%;
       transform: translateX(-50%);
       overflow: hidden;
       width: 100%;
   
       @include md{
        position: absolute;
        bottom: 10px;
    
        color: black;
       
        left: 50%;
        transform: translateX(-50%);
        overflow: hidden;
      
       }
       @include xl{
        position: absolute;
        bottom: 10px;
    
        color: black;
       
        left: 50%;
        transform: translateX(-50%);
        overflow: hidden;
        width: 65%;
        margin: 0 auto;
       }
       .add-to-cart4{
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 400;
        margin: 0 auto;
        text-align: center;
        
        
        @include md{
            text-transform: uppercase;
            font-size: 13px;
            margin: 0 auto;
            text-align: center;
        }
        @include xl{
            text-transform: uppercase;
            font-size: 13px;
            margin: 0 auto;
            text-align: center;
        }
        @include xxl{
            text-transform: uppercase;
            font-size: 13px;
            margin: 0 auto;
            text-align: center;
            padding: 4px 0;


            &:hover{
                border: 1px solid rgb(128, 128, 128,0.4);
                border-radius: 2px;
                background-color: rgb(7, 123, 196,0.8);
                color: #fff;
                transition: 0.4s ease-in;
               }
        }
       }
     

    }
    .imgContainer4{
        position: relative;
        overflow: hidden;
        @include sm{
            .quickView4{
                display: none;
            }
        }
        @include xxl{
            .quickView4{
                display: none;
            }
            &:hover{
                .img{
                    transform: scale(1.1);
                    transition: 1.1s ease-in;
                }
                .quickView4{
                    display: block;
                    position: absolute;
                    background-color: rgb(0, 0, 0,0.6);
                    color: white;
                    top: 15px;
                    right: 18px;
                    padding: 4px;
                    border: 1px solid rgba(0,0,0,0.2);
                    border-radius: 10px;
                    transform: translateX(100%);
                    animation: slide 0.5s ease forwards;
                }
            }
        }
    }
    .stockAndPrice{
        .stock4{
            color: rgba(0,0,0,0.7);
        }
    }
}
@keyframes slide {
    0%{transform: translateX(100%);}
    100%{transform: translateX(0);}
}