.quickView5{
    background-color: rgb(0, 0, 0,0.6);
    color: white;
    top: 15px;
    right: 18px;
    padding: 4px;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 10px;
    transform: translateX(100%);
    animation: slide 0.5s ease forwards;
}
@keyframes slide {
    0%{transform: translateX(100%);}
    100%{transform: translateX(0);}
}