/* PaymentSuccess.css */

.success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
  }
  
  .checkmark-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #4caf50;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
    animation: bounceIn 0.6s ease-out;
  }
  
  .checkmark {
    width: 0;
    height: 0;
    border-right: 5px solid white;
    border-bottom: 5px solid white;
    transform: rotate(45deg);
    position: absolute;
    top: 10px;
    left: 30px;
    opacity: 0;
    animation: drawCheckmark 0.5s ease-in-out 0.3s forwards;
  }
  
  .successh2 {
    color: #4caf50;
    animation: fadeIn 1s ease-in-out 0.5s forwards;
    opacity: 0;
  }
  .successp{
    animation: fadeIn 1s ease-in-out 0.5s forwards;
    opacity: 0;
  }
  .button{
    animation: fadeIn 1s ease-in-out 0.5s forwards;
    opacity: 0;
  }
  @keyframes bounceIn {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    80% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes drawCheckmark {
    0% {
      width: 0;
      height: 0;
      opacity: 0;
    }
    100% {
      width: 25px;
      height: 50px;
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  