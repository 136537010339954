@import "../../../css-config/mixins.scss";

.homeDecorContainer{
    width: 100%;
    border: 1px solid rgba(0,0,0,0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    .bannerAndFeatureSection{
        max-width: 0%;
        // max-height: 100%;
        display: none;
        // border: 1px solid rgba(0,0,0,0.2);
        .bannerPart{
            width: 100%;
            max-height: 230px;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            border: 1px solid rgba(0,0,0,0.4);
            margin-left: 15px;
            margin-top: 30px;
            margin-bottom: 50px;
            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.2);
                opacity: 1;
                z-index: 2;
            }
            &:hover::before{
                opacity: 1;
                background-color: rgba(0,0,0,0.4);
                transition: 0.5s ease-out;
            }
            img{
                width: 100%;
                height: 100%;
                overflow: hidden;
                object-fit: cover;
                // position: relative;
                z-index: 1;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                    transition: 0.7s ease-in;
                }
            }
            h1{
                position: absolute;
                top: 25px;
                left: 40px;
                text-transform: capitalize;
                color: white;
                font-size: 50px;
                z-index: 3;
                padding: 0 6px 10px 4px;
            }
            button{
                text-transform: uppercase;
                background-color: white;
                position: absolute;
                bottom: 15px;
                left: 45px;
                padding: 7px 14px;
                margin-top: 10px;
                z-index: 4;
                border: 1px solid rgba(0,0,0,0.2);
                border-radius: 50px;
                &:hover{
                    background-color: #077bc4;
                    color: white;
                    transition: 0.5s ease-in;
                }
            }
        }
        .featuredPart{
            width: 100%;
            background-color: black;
            margin-left: 15px;
            // margin-top: 20px;
            margin-bottom: 20px;
            max-height: 800px;
            h1{
                text-transform: uppercase;
                color: white;
                font-size: 26px;
                padding: 12px 8px;
                // font: bolder;
                // display: flex;
                // margin: 0 auto;
                text-align: center;
            }
        }
    }
    .homeDecorProductSection{
        max-width: 100%;
        background-color: black;
        overflow: hidden;
        .heading{
            color: white;
            text-transform: uppercase;
            padding: 5px 0 1px 5px; 
            font-size: 16px;
            position: relative;
            &::after{
                content: "";
                position: absolute;
                bottom: -3px;
                left: 10px;
                width: 40%;
                height: 3px;
                background-color: #077bc4;
            }
        }
        .subCategories{
            display: flex;
            align-items: center;
            gap: 20px;
            text-transform: capitalize;
            color: white;
            font-size: 13px;
            font-weight: 500;
            margin-top: 5px;
            margin-left: 10px;
            margin-bottom: 5px;
            border-bottom: 3px solid rgb(255, 255, 255,0.9);
            span{
                padding: 2px 7px;
                cursor: pointer;
                &:hover{
                    color: #077bc4;
                }
            }
            
        }
        .homeDecorProductCard{
            margin: 5px 5px;
        }
        @include sm{
            max-width: 100%;
            background-color: black;
            overflow: hidden;
            .headingSbcategory{
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 5px;
                h1{
                    color: white;
                    text-transform: uppercase;
                    padding: 5px 0 0 15px; 
                    font-size: 16px;
                    margin-bottom: 5px;
                    position: relative;
                    &::after{
                        content: "";
                        position: absolute;
                        bottom: -3px;
                        left: 15px;
                        width: 15%;
                        height: 3px;
                        background-color: #077bc4;
                    }
                }
                .subCategories{
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    text-transform: capitalize;
                    color: white;
                    font-size: 13px;
                    font-weight: 500;
                    margin-top: 5px;
                    margin-left: 10px;
                    margin-bottom: 5px;
                    border-bottom: 0;
                    span{
                        cursor: pointer;
                        &:hover{
                            color: #077bc4;
                        }
                    }
                    
                }
            }
            .homeDecorProductCard{
                margin: 5px 10px;
            }
        }
    }
    @include md{
        width: 100%;
        // max-height: 1200px;
        border: 1px solid rgba(0,0,0,0.2);
        overflow: hidden;
        .bannerAndFeatureSection{
            max-width: 0%;
            // max-height: 100%;
            display: none;
            // border: 1px solid rgba(0,0,0,0.2);
        }
        .homeDecorProductSection{
            width: 100%;
            height: 100%;
            background-color: black;
            overflow: hidden;
            margin: 20px 20px 20px 20px;
            .headingSbcategory{
                display: flex;
                flex-direction: row;
                justify-content: start;
                align-items: center;
                gap: 20px;
                h1{
                    color: white;
                    text-transform: uppercase;
                    padding: 20px 0 0 20px; 
                    font-size: 16px;
                    margin-bottom: 20px;
                    position: relative;
                    &::after{
                        content: "";
                        position: absolute;
                        bottom: -3px;
                        left: 15px;
                        width: 15%;
                        height: 3px;
                        background-color: #077bc4;
                    }
                }
                .subCategories{
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    text-transform: capitalize;
                    color: white;
                    font-size: 13px;
                    font-weight: 500;
                    margin-top: 17px;
                    margin-left: 0;
                    margin-bottom: 20px;
                    border-bottom: 0;
                    span{
                        cursor: pointer;
                        &:hover{
                            color: #077bc4;
                        }
                    }
                    
                }
            }
            .homeDecorProductCard{
                margin: 0 25px 23px 25px;
            }
        }
    }
    @include lg{
                // width: 100%;
        // max-height: 1200px;
        border: 1px solid rgba(0,0,0,0.2);
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        .bannerAndFeatureSection{
            max-width: 0%;
            // max-height: 100%;
            display: none;
            // border: 1px solid rgba(0,0,0,0.2);
            .bannerPart{
                width: 100%;
                max-height: 230px;
                overflow: hidden;
                position: relative;
                cursor: pointer;
                border: 1px solid rgba(0,0,0,0.4);
                margin-left: 15px;
                margin-top: 30px;
                margin-bottom: 50px;
                &::before{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0,0,0,0.2);
                    opacity: 1;
                    z-index: 2;
                }
                &:hover::before{
                    opacity: 1;
                    background-color: rgba(0,0,0,0.4);
                    transition: 0.5s ease-out;
                }
                img{
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    object-fit: cover;
                    // position: relative;
                    z-index: 1;
                }
                &:hover{
                    img{
                        transform: scale(1.1);
                        transition: 0.7s ease-in;
                    }
                }
                h1{
                    position: absolute;
                    top: 25px;
                    left: 40px;
                    text-transform: capitalize;
                    color: white;
                    font-size: 50px;
                    z-index: 3;
                    padding: 0 6px 10px 4px;
                }
                button{
                    text-transform: uppercase;
                    background-color: white;
                    position: absolute;
                    bottom: 15px;
                    left: 45px;
                    padding: 7px 14px;
                    margin-top: 10px;
                    z-index: 4;
                    border: 1px solid rgba(0,0,0,0.2);
                    border-radius: 50px;
                    &:hover{
                        background-color: #077bc4;
                        color: white;
                        transition: 0.5s ease-in;
                    }
                }
            }
            .featuredPart{
                width: 100%;
                background-color: black;
                margin-left: 15px;
                // margin-top: 20px;
                margin-bottom: 20px;
                max-height: 800px;
                h1{
                    text-transform: uppercase;
                    color: white;
                    font-size: 26px;
                    padding: 12px 8px;
                    // font: bolder;
                    // display: flex;
                    // margin: 0 auto;
                    text-align: center;
                }
            }
        }
        .homeDecorProductSection{
            width: 100%;
            height: 100%;
            background-color: black;
            overflow: hidden;
            margin: 10px 10px 10px 10px;
            .headingSbcategory{
                display: flex;
                align-items: center;
                gap: 20px;
                h1{
                    color: white;
                    text-transform: uppercase;
                    padding: 20px 0 0 20px; 
                    font-size: 16px;
                    margin-bottom: 20px;
                    position: relative;
                    &::after{
                        content: "";
                        position: absolute;
                        bottom: -3px;
                        left: 15px;
                        width: 15%;
                        height: 3px;
                        background-color: #077bc4;
                    }
                }
                .subCategories{
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    text-transform: capitalize;
                    color: white;
                    font-size: 13px;
                    font-weight: 500;
                    margin-top: 17px;
                    margin-left: 0;
                    margin-bottom: 20px;
                    border-bottom: 0;
                    span{
                        cursor: pointer;
                        &:hover{
                            color: #077bc4;
                        }
                    }
                    
                }
            }
            .homeDecorProductCard{
                margin: 0 15px 15px 15px;
            }
        }
    }
    @include xl{
                // width: 100%;
        // max-height: 1200px;
        border: 1px solid rgba(0,0,0,0.2);
        display: flex;
        align-items: start;
        justify-content: space-between;
        overflow: hidden;
        .bannerAndFeatureSection{
            max-width: 30%;
            // max-height: 100%;
            display: block;
            // border: 1px solid rgba(0,0,0,0.2);
            .bannerPart{
                width: 100%;
                max-height: 180px;
                overflow: hidden;
                position: relative;
                cursor: pointer;
                border: 1px solid rgba(0,0,0,0.4);
                margin-left: 0;
                margin-top: 10px;
                margin-bottom: 15px;
                &::before{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0,0,0,0.2);
                    opacity: 1;
                    z-index: 2;
                }
                &:hover::before{
                    opacity: 1;
                    background-color: rgba(0,0,0,0.4);
                    transition: 0.5s ease-out;
                }
                img{
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    object-fit: cover;
                    // position: relative;
                    z-index: 1;
                }
                &:hover{
                    img{
                        transform: scale(1.1);
                        transition: 0.7s ease-in;
                    }
                }
                h1{
                    position: absolute;
                    top: 25px;
                    left: 40px;
                    text-transform: capitalize;
                    color: white;
                    font-size: 25px;
                    z-index: 3;
                    padding: 0 6px 10px 4px;
                }
                button{
                    text-transform: uppercase;
                    background-color: white;
                    position: absolute;
                    bottom: 15px;
                    left: 45px;
                    padding: 7px 14px;
                    margin-top: 10px;
                    z-index: 4;
                    border: 1px solid rgba(0,0,0,0.2);
                    border-radius: 50px;
                    font-size: 13px;
                    &:hover{
                        background-color: #077bc4;
                        color: white;
                        transition: 0.5s ease-in;
                    }
                }
            }
            .featuredPart{
                width: 100%;
                background-color: black;
                margin-left: 0;
                // margin-top: 20px;
                margin-bottom: 20px;
                height: auto;
                padding-bottom: 10px;
                h1{
                    text-transform: uppercase;
                    color: white;
                    font-size: 16px;
                    font-weight: 400;
                    padding: 12px 8px;
                    // font: bolder;
                    // display: flex;
                    // margin: 0 auto;
                    text-align: center;
                }
            }
        }
        .homeDecorProductSection{
            max-width: 70%;
            height: 100%;
            background-color: black;
            overflow: hidden;
            margin: 10px 10px 10px 15px;
            .headingSbcategory{
                display: flex;
                align-items: start;
                gap: 20px;
                h1{
                    color: white;
                    text-transform: uppercase;
                    padding: 15px 0 0 15px; 
                    font-size: 16px;
                    margin-bottom: 20px;
                    position: relative;
                    &::after{
                        content: "";
                        position: absolute;
                        bottom: -3px;
                        left: 15px;
                        width: 24%;
                        height: 3px;
                        background-color: #077bc4;
                    }
                }
                .subCategories{
                    display: flex;
                    align-items: start;
                    gap: 15px;
                    text-transform: capitalize;
                    color: white;
                    font-size: 13px;
                    margin-top: 15px;
                    margin-left: 0;
                    margin-bottom: 15px;
                    border-bottom: 0;
                    span{
                        cursor: pointer;
                        &:hover{
                            color: #077bc4;
                        }
                    }
                    
                }
            }

            .homeDecorProductCard{
                margin: 0 10px 10px 10px;
            }
        }
    }
    @include xxl{
                // width: 100%;
        // max-height: 1200px;
        border: 1px solid rgba(0,0,0,0.2);
        display: flex;
        align-items: start;
        justify-content: space-between;
        overflow: hidden;
        .bannerAndFeatureSection{
            max-width: 30%;
            // max-height: 100%;
            display: block;
            // border: 1px solid rgba(0,0,0,0.2);
            .bannerPart{
                width: 100%;
                max-height: 160px;
                overflow: hidden;
                position: relative;
                cursor: pointer;
                border: 1px solid rgba(0,0,0,0.4);
                margin-left: 0;
                margin-top: 10px;
                margin-bottom: 17px;
                &::before{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0,0,0,0.2);
                    opacity: 1;
                    z-index: 2;
                }
                &:hover::before{
                    opacity: 1;
                    background-color: rgba(0,0,0,0.4);
                    transition: 0.5s ease-out;
                }
                img{
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    object-fit: cover;
                    // position: relative;
                    z-index: 1;
                }
                &:hover{
                    img{
                        transform: scale(1.1);
                        transition: 0.7s ease-in;
                    }
                }
                h1{
                    position: absolute;
                    top: 25px;
                    left: 40px;
                    text-transform: capitalize;
                    color: white;
                    font-size: 20px;
                    z-index: 3;
                    padding: 0 6px 10px 4px;
                }
                button{
                    text-transform: uppercase;
                    background-color: white;
                    position: absolute;
                    bottom: 15px;
                    left: 45px;
                    font-size: 13px;
                    padding: 5px 12px;
                    margin-top: 10px;
                    z-index: 4;
                    border: 1px solid rgba(0,0,0,0.2);
                    border-radius: 50px;
                    &:hover{
                        background-color: #077bc4;
                        color: white;
                        transition: 0.5s ease-in;
                    }
                }
            }
            .featuredPart{
                width: 100%;
                background-color: black;
                margin-left: 0;
                margin-top: 0;
                margin-bottom: 10px;
                height: auto;
                h1{
                    text-transform: uppercase;
                    color: white;
                    font-size: 16px;
                    padding: 12px 8px;
                    // font: bolder;
                    // display: flex;
                    // margin: 0 auto;
                    text-align: center;
                }
            }
        }
        .homeDecorProductSection{
            max-width: 70%;
            height: 100%;
            background-color: black;
            overflow: hidden;
            margin: 10px 10px 10px 15px;
            .headingSbcategory{
                display: flex;
                align-items: start;
                gap: 20px;
              h1{
                color: white;
                text-transform: uppercase;
                padding: 15px 10px 0 15px;
                margin-bottom: 20px; 
                font-size: 16px;
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    bottom: -3px;
                    left: 22px;
                    width: 25%;
                    height: 3px;
                    background-color: #077bc4;
                }
              }
              .subCategories{
                display: flex;
                align-items: start;
                gap: 15px;
                text-transform: capitalize;
                color: white;
                font-size: 13px;
                font-weight:500;
                margin-top: 15px;
                margin-left: 0;
                margin-bottom: 15px;
                border-bottom: 0;
                span{
                    cursor: pointer;
                    &:hover{
                        color: #077bc4;
                    }
                }
                
            }
            }

            .homeDecorProductCard{
                margin: 0 15px 15px 15px;
            }
        }
    }

}