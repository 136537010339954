@import "../../../css-config/mixins.scss";
.productSection{
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    margin-top: 15px;
    overflow: hidden;
    .prodContainer{
        overflow: hidden;
        width: 100%;
        height: 100%;
        // margin-top: 8px;
        // margin-bottom: 6px;
        z-index: 7;
        display: flex;
        align-items: center;
        img{
            height: 62px;
            width: 62px;
            object-fit: cover;
            overflow: hidden;
            margin: 0 25px 0 5px;
            cursor: pointer;
            &:hover{
                transform: scale(0.8);
                transition: 0.3s ease-in;
            }
        }
        .textAndPrice{
            display: flex;
            flex-direction: column;
            h4{
                font-weight: 500;
                font-size: 11px;
                cursor: pointer;
                &:hover{
                    color: gray;
                }
            }
        }
    }

    @include xxl{
        width: 100%;
        height: 100%;
        background-color: rgb(255, 255, 255);
        margin-top: 15px;
        overflow: hidden;
        .prodContainer{
            overflow: hidden;
            width: 100%;
            height: 100%;
            // margin-top: 12px;
            // margin-bottom: 12px;
            z-index: 7;
            display: flex;
            align-items: center;
            img{
                height:65px;
                width: 65px;
                object-fit: cover;
                overflow: hidden;
                margin: 0 16px;
                cursor: pointer;
                &:hover{
                    transform: scale(0.8);
                    transition: 0.3s ease-in;
                }
            }
            .textAndPrice{
                display: flex;
                flex-direction: column;
                h4{
                    font-weight: 500;
                    font-size: 11px;
                    cursor: pointer;
                    &:hover{
                        color: gray;
                    }
                }
            }
        }
        &:hover{
            transform: scale(1.001);
            transition: 0.3s ease-in;
        }
    }
}