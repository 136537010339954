.payment-failure-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #f8d7da;
    padding: 20px;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    width: 300px;
    // height: 100%;
    margin: auto;
  }
  
  .icon-failure {
    position: relative;
    width: 60px;
    height: 60px;
    // margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #f5c6cb;
    border: 2px solid #d9534f;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: shake 0.5s ease-in-out;
  }
  
  .cross {
    // position: relative;
    width: 20px;
    height: 20px;
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // justify-content: center;
  }
  

  
  .line1 {
    // transform: rotate(45deg);
    animation: lineGrow 0.4s ease-in-out;
  }
  
  
  .h2 {
    color: #d9534f;
    margin: 10px 0;
    // font-size: 22px;
  }
  
  .p {
    color: #721c24;
    // font-size: 1em;
  }
  
  @keyframes shake {
    0%, 100% { transform: translateX(0); }
    25% { transform: translateX(-10px); }
    50% { transform: translateX(10px); }
    75% { transform: translateX(-10px); }
  }
  
  @keyframes lineGrow {
    from {
      height: 0;
    }
    to {
      height: 20px;
    }
  }
  
  