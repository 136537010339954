@import "../../../css-config/mixins.scss";

.bannerContainer{
    overflow: hidden;
    margin: 5px auto;
    border: 0;
    .imgContainer{
        width: 100%;
        height: 100px;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    @include md{
        overflow: hidden;
        margin: 20px auto;
        border: 1px solid rgba(0,0,0,0.2);
        .imgContainer{
            width: 100%;
            height: 160px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    @include lg{
        overflow: hidden;
        margin: 30px auto;
        border: 1px solid rgba(0,0,0,0.2);
        .imgContainer{
            width: 100%;
            height: 200px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    @include xl{
        overflow: hidden;
        margin: 40px auto;
        border: 1px solid rgba(0,0,0,0.2);
        .imgContainer{
            width: 100%;
            height: 180px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    @include xxl{
        overflow: hidden;
        margin: 50px auto;
        border: 1px solid rgba(0,0,0,0.2);
        .imgContainer{
            width: 100%;
            height: 150px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}