@import "../../../css-config/mixins.scss";
.imageContainer{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid rgba(0,0,0,0.4) ;
    overflow: hidden;
    .firstImg{
        width: 100%;
        height: 120px;
        overflow: hidden;
        margin-bottom: 2px;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            overflow: hidden;
            position: relative;
            z-index: 1;
            cursor: pointer;
        }
    }
    .midTwoimg{
        width: 100%;
        height: 240Px;
        overflow: hidden;
        .midImg1{
            width: 100%;
            max-height: 120px;
            margin-bottom: 2px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                overflow: hidden;
                cursor: pointer;
            }
        }
        .midImg2{
            width: 100%;
            max-height: 120px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                overflow: hidden;
                cursor: pointer;
            }
        }   
    }
    @include sm{
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        border: 1px solid rgba(0,0,0,0.4) ;
        overflow: hidden;
        .firstImg{
            width: 100%;
            height: 200px;
            overflow: hidden;
            margin-bottom: 2px;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                overflow: hidden;
                position: relative;
                z-index: 1;
                cursor: pointer;
            }
        }
        .midTwoimg{
            width: 100%;
            height: 400Px;
            overflow: hidden;
            .midImg1{
                width: 100%;
                max-height: 200px;
                margin-bottom: 2px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    overflow: hidden;
                    cursor: pointer;
                }
            }
            .midImg2{
                width: 100%;
                max-height: 200px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    overflow: hidden;
                    cursor: pointer;
                }
            }   
        }
    }
    @include md{
        width: 100%;
        max-height: 400px;
        margin-left: 0;
        margin-top: 50px;
        margin-bottom: 50px;
        border: 1px solid rgba(0,0,0,0.4) ;
        .firstImg{
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;
            margin-bottom: 0;
            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.1);
                opacity: 1;
                z-index: 2;
            }
            &:hover::before{
                opacity: 1;
                background-color: rgba(0,0,0,0.5);
                transition: 0.5s ease-out;
            }
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                overflow: hidden;
                position: relative;
                z-index: 1;
                cursor: pointer;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                    transition: 0.7s ease-in;
                }
            }
        }
        .midTwoimg{
            width: 100%;
            height: 400Px;
            overflow: hidden;
            .midImg1{
                width: 100%;
                max-height: 200px;
                margin-bottom: 0;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    overflow: hidden;
                    cursor: pointer;
                    &:hover{
                        transform: scale(1.07);
                        transition: 0.7s ease-in;
                    }
                }
            }
            .midImg2{
                width: 100%;
                max-height: 200px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    overflow: hidden;
                    cursor: pointer;
                    &:hover{
                        transform: scale(1.06);
                        transition: 0.7s ease-in;
                    }
                }
            }
    
            }
    }
    @include lg{
        width: 100%;
        max-height: 400px;
        margin-left: 0;
        margin-top: 50px;
        margin-bottom: 50px;
        border: 1px solid rgba(0,0,0,0.4) ;
        .firstImg{
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;
            margin-bottom: 0;
            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.1);
                opacity: 1;
                z-index: 2;
            }
            &:hover::before{
                opacity: 1;
                background-color: rgba(0,0,0,0.5);
                transition: 0.5s ease-out;
            }
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                overflow: hidden;
                position: relative;
                z-index: 1;
                cursor: pointer;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                    transition: 0.7s ease-in;
                }
            }
        }
        .midTwoimg{
            width: 100%;
            height: 400Px;
            overflow: hidden;
            .midImg1{
                width: 100%;
                max-height: 200px;
                margin-bottom: 0;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    overflow: hidden;
                    cursor: pointer;
                    &:hover{
                        transform: scale(1.07);
                        transition: 0.7s ease-in;
                    }
                }
            }
            .midImg2{
                width: 100%;
                max-height: 200px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    overflow: hidden;
                    cursor: pointer;
                    &:hover{
                        transform: scale(1.06);
                        transition: 0.7s ease-in;
                    }
                }
            }
    
            }
    }
    @include xl{
        width: 100%;
        max-height: 400px;
        margin-left: 0;
        margin-top: 50px;
        margin-bottom: 50px;
        border: 1px solid rgba(0,0,0,0.4) ;
        .firstImg{
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;
            margin-bottom: 0;
            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.1);
                opacity: 1;
                z-index: 2;
            }
            &:hover::before{
                opacity: 1;
                background-color: rgba(0,0,0,0.5);
                transition: 0.5s ease-out;
            }
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                overflow: hidden;
                position: relative;
                z-index: 1;
                cursor: pointer;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                    transition: 0.7s ease-in;
                }
            }
        }
        .midTwoimg{
            width: 100%;
            height: 400px;
            overflow: hidden;
            .midImg1{
                width: 100%;
                max-height: 200px;
                margin-bottom: 0;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    overflow: hidden;
                    cursor: pointer;
                    &:hover{
                        transform: scale(1.07);
                        transition: 0.7s ease-in;
                    }
                }
            }
            .midImg2{
                width: 100%;
                max-height: 200px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    overflow: hidden;
                    cursor: pointer;
                    &:hover{
                        transform: scale(1.06);
                        transition: 0.7s ease-in;
                    }
                }
            }
    
            }
    }
    @include xxl{
        width: 100%;
        max-height: 350px;
        margin-left: 0;
        margin-top: 50px;
        margin-bottom: 50px;
        border: 1px solid rgba(0,0,0,0.4) ;
        .firstImg{
            width: 100%;
            height: 350px;
            overflow: hidden;
            position: relative;
            margin-bottom: 0;
            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.1);
                opacity: 1;
                z-index: 2;
            }
            &:hover::before{
                opacity: 1;
                background-color: rgba(0,0,0,0.5);
                transition: 0.5s ease-out;
            }
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                overflow: hidden;
                position: relative;
                z-index: 1;
                cursor: pointer;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                    transition: 0.7s ease-in;
                }
            }
        }
        .midTwoimg{
            width: 100%;
            height: 350Px;
            overflow: hidden;
            .midImg1{
                width: 100%;
                max-height: 175px;
                margin-bottom: 0;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    overflow: hidden;
                    cursor: pointer;
                    &:hover{
                        transform: scale(1.07);
                        transition: 0.7s ease-in;
                    }
                }
            }
            .midImg2{
                width: 100%;
                max-height: 175px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    overflow: hidden;
                    cursor: pointer;
                    &:hover{
                        transform: scale(1.06);
                        transition: 0.7s ease-in;
                    }
                }
            }
    
            }
    }
    .lastImg{
        width: 100%;
        height: 120px;
        overflow: hidden;
        margin-top: 2px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            overflow: hidden;
            cursor: pointer;
        }
        @include sm{
            width: 100%;
            height: 200px;
            overflow: hidden;
            margin-top: 2px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                overflow: hidden;
                cursor: pointer;
            }
        }
        @include md{
            width: 100%;
            height: 400px;
            overflow: hidden;
            margin-top: 0;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                overflow: hidden;
                cursor: pointer;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                    transition: 0.7s ease-in;
                }
            }
        }
        @include lg{
            width: 100%;
            height: 400px;
            overflow: hidden;
            margin-top: 0;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                overflow: hidden;
                cursor: pointer;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                    transition: 0.7s ease-in;
                }
            }
        }
        @include xl{
            width: 100%;
            height: 400px;
            overflow: hidden;
            margin-top: 0;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                overflow: hidden;
                cursor: pointer;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                    transition: 0.7s ease-in;
                }
            }
        }
        @include xxl{
            width: 100%;
            height: 350px;
            overflow: hidden;
            margin-top: 0;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                overflow: hidden;
                cursor: pointer;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                    transition: 0.7s ease-in;
                }
            }
        }
    }
}