.catDataS{
    border-top: 1px solid rgba(0,0,0,0.1);
    cursor: pointer;
    &:nth-child(1){
        border-top: 0;
    }
    &:nth-child(8){
        border-bottom: 0 !important;
    }
    .arrowM{
        font-size: 13px;
        font-weight: 400;
        transform: rotate(0deg);
        transition: all 0.8s ease;
    }
    .subCatM{
        opacity: 0;
        max-height: 0;
        overflow-y: hidden;
        transition: all 1s ease;
    }
}
.catDataS{
    &.open{
        .arrowM{
            font-size: 13px;
            font-weight: 400;
            transform: rotate(180deg);
            transition: all 0.8s ease;
        }
        .subCatM{
            opacity: 1;
            max-height: 240px;
            // transition: all 0.8s ease !important;
        }
    }
}

