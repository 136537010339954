@import "../../css-config/mixins.scss";

.card-container{
    .quantity{
        width: 95%;
        color: white;
        display: flex;
        margin-top: 10px;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border: 1px solid rgb(255, 255, 255,0.5);
        border-radius: 80px;
        padding:  0;
        span{
            &:nth-child(1){
                border-right: 1px solid rgb(255, 255, 255,0.5);
                margin-left: 15px;
                padding-right: 8px;
                font-size: 16px;
            }
            &:nth-child(3){
                border-left: 1px solid rgb(255, 255, 255,0.5);
                margin-right: 15px;
                padding-left: 8px;
                font-size: 16px;
            }
            
        }
        @include sm{
            width: 60%;
            color: white;
            display: flex;
            margin-top: 17px;
            justify-content: center;
            align-items: center;
            gap: 15px;
            border: 1px solid rgb(255, 255, 255,0.5);
            border-radius: 80px;
            padding: 1px 0;
            span{
                &:nth-child(1){
                    border-right: 1px solid rgb(255, 255, 255,0.5);
                    margin-left: 5px;
                    padding-right: 8px;
                    font-size: 13px;
                    // width: 100%;
                }
                &:nth-child(3){
                    border-left: 1px solid rgb(255, 255, 255,0.5);
                    margin-right: 5px;
                    padding-left: 8px;
                    font-size: 13px;
                    // height: 100%;
                }
                
            }
        }
        @include md{
            width: 50%;
            color: white;
            display: flex;
            margin-top: 15px;
            justify-content: center;
            align-items: center;
            gap: 15px;
            border: 1px solid rgb(255, 255, 255,0.5);
            border-radius: 80px;
            padding:  0;
            span{
                &:nth-child(1){
                    border-right: 1px solid rgb(255, 255, 255,0.5);
                    margin-left: 10px;
                    padding-right: 6px;
                    font-size: 13px;
                    width: 100%;
                }
                &:nth-child(3){
                    border-left: 1px solid rgb(255, 255, 255,0.5);
                    margin-right: 10px;
                    padding-left: 6px;
                    font-size: 13px;
                    // height: 100%;
                }
                
            }
        }
        @include lg{
            width: 50%;
            color: white;
            display: flex;
            margin-top: 12px;
            justify-content: center;
            align-items: center;
            gap: 15px;
            border: 1px solid rgb(255, 255, 255,0.5);
            border-radius: 80px;
            padding:  0;
            span{
                &:nth-child(1){
                    border-right: 1px solid rgb(255, 255, 255,0.5);
                    margin-left: 10px;
                    padding-right: 6px;
                    font-size: 13px;
                    width: 100%;
                }
                &:nth-child(3){
                    border-left: 1px solid rgb(255, 255, 255,0.5);
                    margin-right: 10px;
                    padding-left: 6px;
                    font-size: 13px;
                    // height: 100%;
                }
                
            }
        }
        @include xl{
            width: 50%;
            color: white;
            display: flex;
            margin-top: 20px;
            justify-content: center;
            align-items: center;
            gap: 15px;
            border: 1px solid rgb(255, 255, 255,0.5);
            border-radius: 80px;
            padding:  0;
            span{
                &:nth-child(1){
                    border-right: 1px solid rgb(255, 255, 255,0.5);
                    margin-left: 10px;
                    padding-right: 5px;
                    font-size: 13px;
                    width: 100%;
                }
                &:nth-child(3){
                    border-left: 1px solid rgb(255, 255, 255,0.5);
                    margin-right: 8px;
                    padding-left: 5px;
                    font-size: 13px;
                    // height: 100%;
                }
                
            }
        }
        @include xxl{
            width: 50%;
            color: white;
            display: flex;
            margin-top: 8px;
            justify-content: center;
            align-items: center;
            gap: 15px;
            border: 1px solid rgb(255, 255, 255,0.5);
            border-radius: 80px;
            padding:  0;
            span{
                &:nth-child(1){
                    border-right: 1px solid rgb(255, 255, 255,0.5);
                    margin-left: 10px;
                    padding-right: 6px;
                    font-size: 13px;
                    width: 100%;
                }
                &:nth-child(3){
                    border-left: 1px solid rgb(255, 255, 255,0.5);
                    margin-right: 10px;
                    padding-left: 5px;
                    font-size: 13px;
                    // height: 100%;
                }
                
            }
        }
    }
    .btn{
        position: absolute;
        bottom: 10px;
       
        color: white;
       
        left: 50%;
        transform: translateX(-49%);
        overflow: hidden;
        width: 100%;
        .add-to-cart{
         text-transform: uppercase;
         font-size: 13px;
         font-weight: 500;
         margin-left: 10px;
        }

        @include sm{
            position: absolute;
            bottom: 10px;
        
            color: white;
      
            left: 50%;
            transform: translateX(-50%);
            overflow: hidden;
            

            .add-to-cart{
             text-transform: uppercase;
             font-size: 13px;
             font-weight: 400;
        
             margin: 0 auto;
             text-align: center;
           
            }
        }
        @include md{
            position: absolute;
            bottom: 15px;
           
            color: white;
          
            left: 50%;
            transform: translateX(-50%);
            overflow: hidden;
          
            .add-to-cart{
             text-transform: uppercase;
             font-size: 13px;
             font-weight: 400;
           
             margin: 0 auto;
             text-align: center;
            }
            
        }
        @include lg{
            position: absolute;
            bottom: 15px;
           
            color: white;
          
            left: 50%;
            transform: translateX(-50%);
            overflow: hidden;
          
            .add-to-cart{
             text-transform: uppercase;
             font-size: 13px;
             font-weight: 400;
            
             margin: 0 auto;
             text-align: center;
            }
         
       
        }
        @include xl{
            position: absolute;
            bottom: 15px;
           
            color: white;
           
            left: 50%;
            transform: translateX(-50%);
            overflow: hidden;
           
            .add-to-cart{
             text-transform: uppercase;
             font-size: 13px;
             font-weight: 400;
            
             margin: 0 auto;
             text-align: center;
            }
           
        }
        @include xxl{
            position: absolute;
            bottom: 15px;
           
            color: white;
        
            left: 50%;
            transform: translateX(-50%);
            overflow: hidden;
            width: 65%;
            margin: 0 auto;
            padding: 5px 0;
            border: 1px solid rgb(0, 0, 0,0.5);
            background-color: #292828;
            border-radius: 4px;
            .add-to-cart{
             text-transform: uppercase;
             font-size: 13px;
             font-weight: 400;
           
             margin: 0 auto;
             text-align: center;
            }
         
            &:hover{
             background-color: #5B5858;
             transition: 0.4s ease-in;
           
            }
        }
     }
    .imgContainer{
        position: relative;
        overflow: hidden;
        @include sm{
            .quickView{
                display: none;
            }
        }
        @include xxl{
            .quickView{
                display: none;
            }
            &:hover{
                .img{
                    transform: scale(1.1);
                    transition: 1.1s ease-in;
                }
                .quickView{
                    display: block;
                    position: absolute;
                    background-color: rgb(0, 0, 0,0.6);
                    color: white;
                    top: 15px;
                    right: 18px;
                    padding: 4px;
                    border: 1px solid rgba(0,0,0,0.2);
                    border-radius: 10px;
                    transform: translateX(100%);
                    animation: slide 0.5s ease forwards;
                }
            }
        }

    }
}
@keyframes slide {
    0%{transform: translateX(100%);}
    100%{transform: translateX(0);}
}